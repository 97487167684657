<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    query="&sort=-id&expand=role"
    entity-name="记录"
    hide-action
    :columns="columns"
    :search-key-type="searchKeyType"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item>
        <a-range-picker
          v-model="form.create_time"
          :placeholder="['开始日期', '结束日期']"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          allowClear
          @change="updateList"
          class="range-picker"
        />
      </a-form-model-item>
    </template>
  </data-list>
</template>
<script>
import { doorRoles } from "../../common/constant/space";
import { getKeyTitle } from "../../common/js/tool";

export default {
  name: "AccessControlRecord",
  data() {
    return {
      url: "/admin/smart-entrance-log",
      searchKeyType: {
        create_time: function(form, key) {
          let value = form[key];
          let res = "";
          if (value?.length > 0) {
            res = `&filter[${key}]=${value[0]} 00:00:00&filter[${key}]=${value[1]} 23:59:59`;
          }
          return res;
        },
      },
      columns: [
        {
          title: "时间",
          dataIndex: "create_time",
          customRender: (text) => {
            return text.slice(0, -3);
          },
        },
        {
          title: "人员",
          dataIndex: "user_id",
          customRender: (text) => {
            return <open-data type="userName" openid={text} />;
          },
        },
        {
          title: "权限",
          dataIndex: "role",
          customRender: (text) => {
            return <span>{getKeyTitle(doorRoles, text, "value", "label")}</span>;
          },
        },
      ],
    };
  },
  computed: {
    dataList() {
      return this.$refs.dataList;
    },
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    updateList() {
      this.dataList.updateList();
    },
  },
};
</script>

<style lang="less" scoped></style>
